<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      v-if="drawer"
      width="320px"
      height="100vh"
      right
      absolute
      temporary
    >
      <v-btn icon @click="toggleDrawer" class="mb-4">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card class="filters-card">
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "advancedFilter",
  data() {
    return { drawer: false, group: null };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
  padding: 12px !important;
}
.filters-card {
  box-shadow: unset !important;
}
</style>
