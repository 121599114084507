<template>
  <div id="search">
    <pageHeader />

    <advancedFilter ref="filterMenu">
      <div class="filters">
        <div class="head d-flex justify-space-between py-3 px-5">
          <p class="mb-0 main-color">بحث متقدم</p>
          <p class="mb-0 second-color clearAll" @click="clearAll()">مسح الكل</p>
        </div>
        <hr class="main-hr" />

        <div class="serviceType py-3 px-5">
          <p class="main-color">مكان الخدمة</p>
          <v-btn
            @click="selectedType('at_salon')"
            :class="selectedServiceType == 'at_salon' ? 'active' : ''"
            class="serviceType-btn"
          >
            الصالون</v-btn
          >
          <v-btn
            @click="selectedType('at_home')"
            :class="selectedServiceType == 'at_home' ? 'active' : ''"
            class="serviceType-btn"
          >
            المنزل</v-btn
          >
        </div>

        <div class="services py-3 px-5">
          <p class="main-color">الخدمات</p>
          <v-checkbox
            v-for="service in services"
            :key="service.id"
            v-model="selectedServices"
            :label="service.name"
            :value="service.id"
            @change="FilterDataAndgetResults()"
          ></v-checkbox>
        </div>

        <div class="sessions py-3 px-5">
          <p class="main-color">الجلسات</p>

          <v-autocomplete
            v-model="selectedSessions"
            :items="sessions"
            item-text="name"
            item-value="id"
            solo
            chips
            small-chips
            multiple
            @change="getResults()"
          ></v-autocomplete>
        </div>

        <div class="gender py-3 px-5">
          <p class="main-color">النوع</p>
          <v-btn
            class="gender-btn"
            :class="selectedGender == 'mix' ? 'active' : ''"
            @click="selectGender('mix')"
            >مختلط</v-btn
          >
          <v-btn
            class="gender-btn"
            :class="selectedGender == 'male' ? 'active' : ''"
            @click="selectGender('male')"
            >رجال</v-btn
          >
          <v-btn
            class="gender-btn"
            :class="selectedGender == 'female' ? 'active' : ''"
            @click="selectGender('female')"
            >سيدات</v-btn
          >
        </div>

        <div class="workingDays py-3 px-5">
          <p class="main-color">ايام العمل</p>
          <v-checkbox
            v-model="selectedDays"
            v-for="day in workingDays"
            :key="day.id"
            :label="day.name"
            :value="day.id"
            @change="getResults()"
          ></v-checkbox>
        </div>
      </div>
    </advancedFilter>
    <div class="search-div mt-4 px-sm-15">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="8" sm="12" class="pa-0"
                ><v-breadcrumbs :items="items">
                  <template v-slot:divider>
                    <v-icon large color="#a53860">mdi-chevron-left</v-icon>
                  </template>
                </v-breadcrumbs></v-col
              >
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="search-div">
            <v-row class="pt-6">
              <v-col cols="12" sm="6" class="py-0 px-1">
                <v-text-field
                  v-model="search"
                  label="بحث"
                  class="curve-input"
                  prepend-inner-icon="mdi-magnify"
                  solo
                  @keyup.enter="getResults('search')"
                >
                  <template slot="append">
                    <v-btn
                      icon
                      class="append-icon"
                      @click="getResults('search')"
                      v-if="!searchCloseBtn"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-btn
                      icon
                      class="append-icon"
                      @click="clearSearch()"
                      v-else
                    >
                      <v-icon color="">mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                </v-text-field></v-col
              >
              <v-col cols="12" sm="6" class="py-0 px-1">
                <v-select
                  :items="locations"
                  v-model="selectedLocation"
                  label="الموقع"
                  prepend-inner-icon="mdi-map-marker"
                  item-text="name"
                  item-value="id"
                  solo
                  @change="getResults('service')"
                >
                  <template slot="append">
                    <v-btn
                      icon
                      class="append-icon"
                      @click="getResults('service')"
                      v-if="!locationCloseBtn"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-btn
                      icon
                      class="append-icon"
                      @click="clearLocation()"
                      v-else
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </template></v-select
                ></v-col
              >

              <v-col cols="12" sm="12" class="py-0 d-md-none">
                <div class="d-flex justify-center align-center">
                  <p class="mb-0">بحث متقدم</p>
                  <v-btn @click="toggleFilterMenu" icon>
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="search-content my-4 px-sm-12">
      <v-container fluid>
        <v-row>
          <v-col cols="3" sm="3" class="d-none d-md-block filters-col">
            <div class="filters">
              <div class="head d-flex justify-space-between py-3 px-5">
                <p class="mb-0 main-color">بحث متقدم</p>
                <p class="mb-0 second-color clearAll" @click="clearAll()">
                  مسح الكل
                </p>
              </div>
              <hr class="main-hr" />

              <div class="serviceType py-3 px-5">
                <p class="main-color">مكان الخدمة</p>
                <v-btn
                  @click="selectedType('at_salon')"
                  :class="selectedServiceType == 'at_salon' ? 'active' : ''"
                  class="serviceType-btn"
                >
                  الصالون</v-btn
                >
                <v-btn
                  @click="selectedType('at_home')"
                  :class="selectedServiceType == 'at_home' ? 'active' : ''"
                  class="serviceType-btn"
                >
                  المنزل</v-btn
                >
              </div>

              <div class="services py-3 px-5">
                <p class="main-color">الخدمات</p>
                <v-checkbox
                  v-for="service in services"
                  :key="service.id"
                  v-model="selectedServices"
                  :label="service.name"
                  :value="service.id"
                  @change="FilterDataAndgetResults()"
                ></v-checkbox>
              </div>

              <div class="sessions py-3 px-5">
                <p class="main-color">الجلسات</p>

                <v-autocomplete
                  v-model="selectedSessions"
                  :items="sessions"
                  item-text="name"
                  item-value="id"
                  solo
                  chips
                  small-chips
                  multiple
                  @change="getResults()"
                ></v-autocomplete>
              </div>

              <div class="gender py-3 px-5">
                <p class="main-color">النوع</p>
                <v-btn
                  class="gender-btn"
                  :class="selectedGender == 'mix' ? 'active' : ''"
                  @click="selectGender('mix')"
                  >مختلط</v-btn
                >
                <v-btn
                  class="gender-btn"
                  :class="selectedGender == 'male' ? 'active' : ''"
                  @click="selectGender('male')"
                  >رجال</v-btn
                >
                <v-btn
                  class="gender-btn"
                  :class="selectedGender == 'female' ? 'active' : ''"
                  @click="selectGender('female')"
                  >سيدات</v-btn
                >
              </div>

              <div class="workingDays py-3 px-5">
                <p class="main-color">ايام العمل</p>
                <v-checkbox
                  v-model="selectedDays"
                  v-for="day in workingDays"
                  :key="day.id"
                  :label="day.name"
                  :value="day.id"
                  @change="getResults()"
                ></v-checkbox>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="9" class="results pa-sm-0 mb-4">
            <v-row v-if="results.length > 0">
              <v-col
                cols="12"
                sm="4"
                md="3"
                class="px-0"
                v-for="(result, index) in results"
                :key="index"
              >
                <v-card
                  class="salon-cart mx-auto mr-0"
                  height="230px"
                  width="95%"
                  :href="`/salon/${result.id}`"
                >
                  <div class="img-div">
                    <img
                      :src="result.main_image"
                      height="230px"
                      width="230px"
                      alt=""
                    />
                  </div>
                  <div class="content">
                    <p class="name my-2">{{ result.name }}</p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else class="no-results">
              <v-col cols="12">
                <p class="font-bold mt-4">لا يوجد نتائج</p>
                <hr class="main-hr" />

                <p class="icon text-center close-icon">
                  <v-icon color="#EFEFEF">mdi-close-circle</v-icon>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pageHeader from "@/components/pageHeader.vue";
import advancedFilter from "@/components/advancedFilter.vue";
export default {
  name: "search",
  components: { pageHeader, advancedFilter },
  data() {
    return {
      searchCloseBtn: false,
      locationCloseBtn: false,
      search: "",
      selectedGender: "",
      selectedServiceType: "at_salon",
      sessions: [],
      selectedSessions: [],
      services: [],
      selectedServices: [],
      selected: [],
      workingDays: [],
      selectedDays: [],
      locations: [],
      results: [],
      selectedLocation: "",
      values: ["foo", "bar"],
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },

        {
          text: "نتائج البحث",
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    toggleFilterMenu() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.$refs.filterMenu.toggleDrawer();
    },
    clearSearch() {
      this.search = "";
      this.searchCloseBtn = false;
      this.getResults();
    },
    clearLocation() {
      this.selectedLocation = "";
      this.locationCloseBtn = false;
      this.getResults();
    },
    getFilterData() {
      this.$http
        .get(
          this.base_url +
            "/front/getDataFilterForSearch?services=" +
            this.selectedServices,
          {}
        )
        .then((response) => {
          this.services = response.data.data.services;
          this.sessions = response.data.data.sessions;
          this.workingDays = response.data.data.working_days;
          this.locations = response.data.data.locations;
        });
    },
    getResults(type) {
      if (type == "search") {
        this.searchCloseBtn = true;
      } else if (type == "service") {
        this.locationCloseBtn = true;
      }
      this.$http
        .get(
          this.base_url +
            "/front/searchInSite?search=" +
            this.search +
            "&services=" +
            this.selectedServices +
            "&sessions=" +
            this.selectedSessions +
            "&services_at=" +
            this.selectedServiceType +
            "&gender=" +
            this.selectedGender +
            "&working_days=" +
            this.selectedDays +
            "&price=&locations=" +
            this.selectedLocation,
          {}
        )
        .then((response) => {
          this.results = response.data.data.items;
        });
    },

    FilterDataAndgetResults() {
      this.getFilterData();
      this.getResults();
    },
    selectGender(gender) {
      if (gender == this.selectedGender) {
        this.selectedGender = "";
      } else {
        this.selectedGender = gender;
      }
      this.getResults();
    },
    selectedType(type) {
      this.selectedServiceType = type;
      this.getResults();
    },
    clearAll() {
      this.selectedServiceType = "";
      this.selectedSessions = [];
      this.selectedServices = [];
      this.selectedGender = "";
      this.selectedDays = [];
      this.getResults();
    },
  },
  mounted() {
    this.getFilterData();
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
    if (this.$route.query.service) {
      this.selectedServices.push(Number(this.$route.query.service));
    }
    this.getResults();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.search-content {
  .filters-col {
    border-left: 1px solid #d9d9d9 !important;
  }
  .filters {
    max-width: 95%;
    background-color: #ffffff;
    box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.15);
    .head {
      .clearAll {
        cursor: pointer;
        font-size: 14px !important;
      }
    }

    .serviceType,
    .gender {
      .serviceType-btn,
      .gender-btn {
        border: 1px solid gray;
        border-radius: 0;
        color: gray;
        padding: 0 12px !important;
        transition: all 0.6s;
      }
    }
  }

  .no-results {
    margin-right: 3rem !important;
    .close-icon {
      margin-top: 10rem;
    }
    .main-hr {
      max-width: 90%;
    }
  }
}

.serviceType-btn:hover,
.gender-btn:hover,
.serviceType-btn.active,
.gender-btn.active {
  background-color: $second-color;
  color: #ffffff !important;
  border-color: $second-color;
}

.close-icon .v-icon {
  font-size: 8rem !important;
}

.serviceType .serviceType-btn:nth-of-type(3),
.gender-btn:nth-of-type(3) {
  border-radius: 10px 0 0 10px !important;
}
.serviceType .serviceType-btn:nth-of-type(1),
.gender-btn:nth-of-type(1) {
  border-radius: 0 10px 10px 0 !important;
}

.serviceType .serviceType-btn:nth-of-type(2) {
  border-radius: 10px 0 0 10px !important;
}

.results {
  .salon-cart {
    .img-div {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      img {
        border-radius: 0 40px 0 0;
        object-fit: fill;
        width: 100%;
      }
    }
    .content {
      color: #ffffff;
      padding: 0 10px;
      position: absolute;
      z-index: 3;
      bottom: -16px;
      right: 10%;
      background-color: #a53860;
      width: 90%;
      border-radius: 2px 15px 2px 2px;

      .name {
        text-align: end;
      }
    }
  }
}

@media (max-width: 600px) {
  .no-results {
    .close-icon {
      margin-top: 3rem !important;
    }
  }
}
</style>
